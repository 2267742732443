.customCardHeader {
  background-color: var(--primary) !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  color: #fff !important;
}
.customCardSubHeader {
  border-bottom: none !important;
  padding: 5px 0px !important;
  /* border: 1px solid !important; */
}
.customCardBody {
  padding-top: 0px !important;
}

.customCardBodyOfTable {
  padding: 0px 10px !important;
}
.customeTable thead th {
  color: #202020 !important;
}
.customeTable thead tr {
  padding: 10px 0px !important;
  background: none !important;
  box-shadow: none !important;
}

.customtable {
  border: 1px solid !important;
}
