.box {
  min-width: 10rem;
  min-height: 5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: #fff;
  cursor: pointer;
}

.box:hover {
  background-color: var(-bs-danger);
}
