.box {
  padding: 1rem;
  border: 1px solid;
  display: flex;
  min-width: 20rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
}

.box:hover {
  background-color: var(-bs-danger);
}
.customSelectBox {
  padding: 0.5rem !important;
  min-width: 20rem !important;
  font-size: 1rem !important;
  cursor: pointer !important;
  border-radius: 0.25rem !important;
  background: #fff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
    no-repeat right 1.5rem center !important;
  background-size: 1rem !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  padding-right: 2rem !important;
}
