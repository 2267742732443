/* Custom Calendar Height */
.myCustomHeight {
  height: 100vh;
}

.rbc-event {
  border-radius: 5px;
  padding: 5px;
}

.rbc-event-label {
  display: none;
}

.customToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.customToolbar button {
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5%;
}

.customToolbarLabel {
  font-size: 24px;
  font-weight: bold;
}
